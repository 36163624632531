import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import i18n from "./i18n";
import "@/scss/custom.scss";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Vue2OrgTree from "vue2-org-tree";

Vue.use(Vue2OrgTree);
Vue.use(VueSweetalert2);

//Truncate text
var filter = function(text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  if (content) {
    return content.length > length ? content.slice(0, length) + clamp : content;
  } else {
    return text;
  }
};
Vue.filter("truncate", filter);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
